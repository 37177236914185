@tailwind base;
@tailwind components;
@tailwind utilities;

.scroll-lock {
  overflow: hidden;
}

html {
  font-family: var(--pretendard), ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #262626;
  background-color: #FAF7F4;
}

.card-bg {
  box-shadow: 0px 0px 40px 0px var(--Red-2, #FFC4C4);
  background-position: 50% 50%;
  z-index: 0;
}

/* Timer */

.react-simple-flipclock {
  display: flex;
  align-items: center;
  height: 23px;
  overflow: hidden;
  font-family: "Arial", sans-serif;
}
.react-simple-flipclock .countdown-separator {
  font-size: 14px;
  font-weight: bold;
  box-sizing: border-box;
  color: #FFF;
}
.react-simple-flipclock .countdown-timer-item {
  border-radius: 4px;
  overflow: hidden;
  width: 7px;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  margin: 0 2px;
  color: #FFF;
}
.react-simple-flipclock .countdown-timer-item .digit-wrapper {
  position: absolute;
  width: 105%;
  transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateY(-90%);
  will-change: transform;
}
.react-simple-flipclock .countdown-timer-item .digit-wrapper > span {
  position: relative;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

/* toggle */

.toggle {
  position: relative;
  cursor: pointer;

  > .toggle-container {
    width: 50px;
    height: 26px;
    border-radius: 30px;
    background-color: #D4D4D4;
  }

  > .toggle--checked {
    background-color: #00A0E7;
    transition: 0.5s;
  }

  > .toggle-circle {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: rgb(255, 254, 255);
    transition: 0.5s;
  }

  > .toggle--checked {
    left: 27px;
    transition: 0.5s;
  }
}
.Toastify__toast {
  background-color: transparent !important;
  box-shadow: none !important;
}

.Toastify__close-button {
  display: none;
}

.mantine-Radio-body {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 6px;
}